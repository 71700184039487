<template>
  <div id="app">
    <MainHeader v-if="this.$route.name !== 'employee'" />
    <EmployeeHeader v-else />
    <router-view/>
    <MainFooter />
  </div>
</template>

<script>
  import MainHeader from '@/components/MainHeader.vue'
  import EmployeeHeader from '@/components/EmployeeHeader.vue'
  import MainFooter from '@/components/MainFooter.vue'

  export default {
    name: 'app',
    components: {
      MainHeader,
      EmployeeHeader,
      MainFooter
    },
    mounted() {
      document.title = this.$t('page_title');
      document.keywords = this.$t('page_keywords');
      document.description = this.$t('page_description');
    }
  }
</script>