import Vue from 'vue'
import App from './App.vue'

import {router} from './router'
import { store } from './store';

import config from 'config';
import "core-js/modules/es.object.entries"
import "core-js/modules/es.array.from.js"

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)

// Firebase
import * as firebase from "firebase/app";
//import "firebase/analytics";
import { getAnalytics, logEvent } from "firebase/analytics";
const app = firebase.initializeApp(config.firebaseConfig)
//Vue.prototype.$analytics = firebase.analytics();
Vue.prototype.$analytics = getAnalytics(app)
Vue.prototype.$logEvent = logEvent


// Boostrap Framework
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Localized Text Libary
import VueI18n from 'vue-i18n'
import messages from './lang';
Vue.use(VueI18n);
const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'th': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  }
};
const defaultLocale = localStorage.getItem('language');
export const i18n = new VueI18n({
  locale: defaultLocale? defaultLocale : 'th',
  fallbackLocale: 'th',
  messages,
  dateTimeFormats
});

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: `${config.gMapKey}`,
    language: i18n.locale,
    libraries: "places"
  }
});

// Axios Setup
import axios from 'axios';
axios.defaults.baseURL = `${config.apiUrl}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$http = axios;


import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

import './scss/style.scss'


Vue.mixin({
  methods: {
    byteToGB: byte => {
      return (byte / 1073741824)
    },
    convertPhone: phone => {
      if(phone === null || phone === undefined || phone === ""){
        return null;
      }
      if(!(phone.indexOf('66') === 0)){
        return phone;
      }else{
        return '0'+phone.substring(2);
      }
    },
    convertPhoneSub: phone => {
      if(phone === null || phone === undefined || phone === ""){
        return null;
      }
      if(phone.indexOf('66') === 0){
        return phone;
      }else{
        return '66'+phone.substring(1);
      }
    },
    getDistanceFromLatLon(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1);
      var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
      ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c; // Distance in km
      if(d <= 1){
        return {distance: (d*1000), unit: 'm'}
      }else{
        return {distance: d, unit: 'km'}
      }
    },
    deg2rad(deg) {
      return deg * (Math.PI/180)
    }
  }
})



new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
