//const lang = localStorage.getItem('language');
import Languages from '../lang'
const supportedLanguages = Object.getOwnPropertyNames(Languages);

export const language = {
  namespaced: true,
  state: {
    language: localStorage.getItem('language'),
  },
  actions: {
    setLanguage({ commit }, languages) {
      if (typeof languages === 'string') {
        commit('SET_LANGUAGE', languages);
      } else {
        const language = supportedLanguages.find(sl =>
          languages.find(l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null)));
        commit('SET_LANGUAGE', language);
      }
    },
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      localStorage.setItem('language', lang)
      state.language = lang;
    },
  }
}
