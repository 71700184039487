<template>
  <footer>
    <div class="position-relative">
      <img src="../assets/logo-footer.svg" alt="dtac">
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'MainFooter',
    computed: {
      user () {
        return this.$store.state.user.subscriber;
      }
    }
  }
</script>

<style lang="scss" scoped>
  footer {
    position: absolute;
    bottom: 0;
    height: 3rem;
    width: 100%;
    text-align: center;
    border-top: 1px solid #f3f3f3;
    background-color: #fff;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      img {
        max-height: 35px;
      }
    }
  }
</style>
