// import { userService } from '../services';
import {numberService} from "@/services";
import {router} from "@/router";

const token = localStorage.getItem('token');
const defaultSubscriber = {msisdn:null, telp_type:'P', contact_number:null, location: {latitude: 13.736717, longitude: 100.523186}}
const defaultBalance = {amount: 0, expired: null, state: 'active'};
const defaultUsage = [];
const initialState = {
  token,
  telpType: null,
  status: {},
  subscriber: defaultSubscriber,
  balance:defaultBalance,
  usage: defaultUsage,
  vasPackage: [],
  packOnShelf: []
};
// const initialState = subscriber
//   ? { token, status: { loggedIn: true }, subscriber, balance:defaultBalance, usage: defaultUsage, vasPackage: [], packOnShelf: []}
//   : { token, status: {}, subscriber: null, balance:defaultBalance, usage: defaultUsage, vasPackage: [], packOnShelf: [] };

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit } ) {
      commit('loginRequest');

      numberService.profile()
        .then(
          subscriber => {
            commit('getProfileSuccess', subscriber)
            router.push('/balance');
          },
          error => commit('getProfileFailure', error)
        );
    },
    getProfile({ commit }) {
      commit('getAllRequest');

      numberService.profile()
        .then(
          subscriber => commit('getProfileSuccess', subscriber),
          error => commit('getProfileFailure', error)
        );
    },
    getBalance({ commit }) {
      //commit('getAllRequest');

      numberService.balance()
        .then(
          balance => commit('getBalanceSuccess', balance),
          error => commit('getBalanceFailure', error)
        );
    },
    getUsage({ commit }) {
      numberService.usage()
        .then(
          usage => commit('getUsageSuccess', usage),
          error => commit('getUsageFailure', error)
        );
    },
    getVasPackage({ commit }) {
      numberService.additionalPack()
        .then(
          usage => commit('getVasPackSuccess', usage),
          error => commit('getVasPackFailure', error)
        );
    },
    getPackOnShelf({ commit }) {
      numberService.packOnShelf()
        .then(
          data => commit('getPackOnShelfSuccess', data),
          error => commit('getPackOnShelfFailure', error)
        );
    },
    // eslint-disable-next-line no-unused-vars
    saveAdditionalPack({ commit }, { telp_type, pack_data }) {
      //Return axios (promise)
      return numberService.saveAdditionalPack(telp_type, pack_data);
    },
    logout({ commit }) {
      numberService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginRequest(state) {
      state.status = { loggingIn: true };
    },
    getProfileSuccess(state, subscriber) {
      state.token = localStorage.getItem('token');
      state.status = { loggedIn: true };
      state.subscriber = subscriber;
      state.telpType = subscriber.telp_type;
    },
    getProfileFailure(state, error) {
      // state.all = { error };
      state.status = {error: error};
      state.subscriber = defaultSubscriber;
    },

    getBalanceSuccess(state, balance) {
      state.balance = balance;
    },
    // eslint-disable-next-line no-unused-vars
    getBalanceFailure(state, error) {
      // state.all = { error };
      state.balance = defaultBalance;
    },

    getUsageSuccess(state, data) {
      console.log(data);
      if(data && data.usage){
        state.usage = data.usage;
      }else{
        state.usage = defaultUsage;
      }
    },
    // eslint-disable-next-line no-unused-vars
    getUsageFailure(state, error) {
      // state.all = { error };
      state.usage = defaultUsage;
    },

    getVasPackSuccess(state, data) {
      if(data && data.vasPackages){
        state.vasPackage = data.vasPackages;
      }else{
        state.vasPackage = [];
      }

    },
    // eslint-disable-next-line no-unused-vars
    getVasPackFailure(state, error) {
      // state.all = { error };
      state.vasPackage = [];
    },
    getPackOnShelfSuccess(state, data) {
      if (data) {
        state.packOnShelf = data.packs;
      } else {        
        // let packs = {
        //   type:"t",
        //   code:"code",
        //   desc:"desc",
        //   price: "10.0",          
        //   shortDesc:"shortDesc"
        // }        
        // state.packOnShelf = [packs];
        state.packOnShelf = [];
      }      
    },
    // eslint-disable-next-line no-unused-vars
    getPackOnShelfFailure(state, error) {
      // state.all = { error };
      state.packOnShelf = [];
    },

    logout(state) {
      state.status = {};
      state.subscriber = defaultSubscriber;
    },

    getAllRequest(state) {
      state.all = { loading: true };
    },

    loginSuccess(state, subscriber) {
      state.status = { loggedIn: true };
      state.subscriber = subscriber;
    },
    loginFailure(state) {
      state.status = {};
      state.subscriber = defaultSubscriber;
    }
  }
}
