import Vue from 'vue';
import Vuex from 'vuex';

import { language } from './language.module';
import { alert } from './alert.module';
import { user } from './user.module';
import { number } from './number.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        language,
        alert,
        user,
        number
    }
});
