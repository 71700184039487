import Vue from 'vue'
import config from 'config'
import { store } from './store';

import Router from 'vue-router'
Vue.use(Router)

import VueScrollTo from'vue-scrollto';
Vue.use(VueScrollTo)

export const router = new Router({
  mode: 'history',
  linkActiveClass: "active",
  //linkExactActiveClass: "active",
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ "./views/Home.vue")
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import(/* webpackChunkName: "faq" */ "./views/FaqPage.vue")
    },
    {
      path: '/how-to-buy',
      name: 'howToBuy',
      component: () => import(/* webpackChunkName: "how-to-buy" */ "./views/HowToBuyPage.vue")
    },
    // StoreLocator.vue
    {
      path: '/store-locator',
      name: 'storeLocator',
      component: () => import(/* webpackChunkName: "store-locator" */ "./views/StoreLocator.vue")
    },
    {
      path: '/term&cond',
      name: 'termsCond',
      component: () => import(/* webpackChunkName: "term&cond" */ "./views/TermsConditions.vue")
    },
    {
      path: '/check-service',
      name: 'checkservice',
      component: () => import(/* webpackChunkName: "check-service" */ "./views/CheckService.vue")
    },
    // {
    //   path: '/balance',
    //   name: 'balance',
    //   component: () => import(/* webpackChunkName: "balance" */ "./views/Balance.vue")
    // },
    // {
    //   path: '/profile',
    //   name: 'profile',
    //   component: () => import(/* webpackChunkName: "profile" */ "./views/Profile.vue")
    // },
    // {
    //   path: '/changepackage',
    //   name: 'changepackage',
    //   component: () => import(/* webpackChunkName: "change-pack" */ "./views/ChangePackage.vue")
    // },
    // {
    //   path: '/topup',
    //   name: 'topup',
    //   component: () => import(/* webpackChunkName: "topup" */ "./views/TopUp.vue")
    // },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue")
    // },
    // {
    //   path: '/logout',
    //   name: 'logout',
    //   redirect: '/login'
    // },
    {
      path: '*',
      name: 'notfound',
      component: () => import(/* webpackChunkName: "not-found" */ "./views/NotFound.vue"),
      meta: {
        title: '404 Page Not Found | dtac',
      }
    },
    {
      path: '/employee-deals',
      name: 'employee',
      component: () => import(/* webpackChunkName: "employee-deals" */ "./views/CheckServiceEmployee.vue")
    },
    {
      path: '/device-contract',
      name: 'contract',
      component: () => import(/* webpackChunkName: "device-contract" */ "./views/DeviceContract.vue")
    },
  ],
  // eslint-disable-next-line
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, 700, {
        offset: -55,
      });

      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  //remove login screen
  // redirect to login page if not logged in and trying to access a restricted page
  //const publicPages = ['home', 'termsCond', "faq", 'howToBuy','checkservice','storeLocator', 'employee', 'login', 'contract'];
  //const authRequired = !publicPages.includes(to.path);
  // const publicPages = ['home', 'termsCond', "faq", 'howToBuy','storeLocator', 'employee', 'contract'];
  // const authRequired = !publicPages.includes(to.name);
  // const loggedIn = localStorage.getItem('token');

  // if (authRequired && !loggedIn) {
  //   return next('/login');
  // }

  // if(authRequired){
  //   console.log('get profile');
  //   store.dispatch('user/getProfile').then(() => next())
  // }
  // else{
  //   next();
  // }
  next();
});

export function authHeader() {
  // return authorization header with token
  let token = localStorage.getItem('token');
  let lang = localStorage.getItem('language');

  if (token) {
    return {
      headers : {
        'Authorization': 'Bearer ' + token,
        'x-api-key': `${config.apiKey}`,
        'x-language': lang,
        "x-client-info": "dah-customer"
      }
    };
  } else {
    return {
      headers : {
        'x-api-key': `${config.apiKey}`,
        'x-language': lang,
        "x-client-info": "dah-customer",
      }
    };
  }
}
