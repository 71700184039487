<template>
<!-- employee -->
  <header class="navbar navbar-expand main-navbar">
    <div class="container flex-md-row flex-wrap flex-md-nowrap">
      <img
        src="../assets/logo-dtac-home.svg"
        class="brand-logo"
        alt="dtac@home"
        style="cursor: pointer;"
        @click="navigateToHomePage"
      />
      <div class="nav-dtac-at-home-info d-md-block" :class="{ show: showMobileMenu }">
        <ul class="navbar-nav py-2 py-md-0">
          <li class="nav-item my-1 my-md-0">
            <div class="menu-item nav-link" @click="navigateToPage('plan')">{{ $t('menu.plan') }}</div>
          </li>
          <li class="nav-item my-1 my-md-0">
            <div
              class="menu-item nav-link"
              @click="navigateToPage('howToBuy')"
            >{{ $t('menu.how_to_buy') }}</div>
          </li>
          <li class="nav-item my-1 my-md-0">
            <div class="menu-item nav-link" @click="navigateToPage('storeLocator')">{{ $t('menu.store_locate') }}</div>
          </li>
          <li class="nav-item my-1 my-md-0">
            <div class="menu-item nav-link" @click="navigateToPage('faq')">{{ $t('menu.faq') }}</div>
          </li>
          <li class="nav-item my-1 my-md-0">
            <div
              class="menu-item nav-link"
              @click="navigateToPage('termsCond')"
            >{{ $t('menu.terms_cond') }}</div>
          </li>
          <li class="nav-item my-1 my-md-0">
            <div
              class="menu-item nav-link"
              @click="navigateToPage('contract')"
            >{{ $t('menu.contract') }}</div>
          </li>
        </ul>
      </div>
      <div class="btn-mobile-menu d-block d-md-none" @click="toggleMenu">
        <div>
          <i class="arrow-yellow"></i>
        </div>
      </div>

      <div class="ml-auto">
        <ul class="navbar-nav flex-row" @click="closeMenu">
          <li class="nav-item lang-changer" :class="{'logged':user.msisdn}">
            <div
              v-if="($i18n.locale==='th')"
              class="nav-link mx-0 p-2"
              @click="changeLanguage('en')"
              style="cursor: pointer;"
            >
              <img src="../assets/flag-en.svg" class="flag" alt="EN" />
            </div>
            <div
              v-else
              class="nav-link mx-0 p-2"
              @click="changeLanguage('th')"
              style="cursor: pointer;"
            >
              <img src="../assets/flag-th.svg" class="flag" alt="TH" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
  
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      showMobileMenu: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user.subscriber;
    },
    telpType() {
      return this.$store.state.user.telpType;
    }
  },
  methods: {
    navigateToHomePage: function() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "home" });
    },
    navigateToPage: function(route_name) {
      this.closeMenu();
      //this.$analytics.logEvent("nav_menu_click", { menu: route_name });
      this.$logEvent(this.$analytics, "nav_menu_click", { menu: route_name });
      switch (route_name) {
        case "plan":
          this.$router.push({ path: "/#sc_plan" });
          break;

        default:
          this.$router.push({ name: route_name })
            .catch(error => {
              if (error.name !== 'NavigationDuplicated' &&
                !error.message.includes('Avoided redundant navigation to current location')) {
                console.log(error)
              }
            });          
          break;
      }
    },
    toggleMenu: function() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    closeMenu: function() {
      this.showMobileMenu = false;
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      //this.$analytics.logEvent("nav_lange_change");
      this.$logEvent(this.$analytics, "nav_lange_change");
      this.$store.dispatch("language/setLanguage", lang);
    }
  },
  created() {
    const token = this.$store.state.user.token;
    if (token) {
      this.$store.dispatch("user/getProfile");
    }
  }
};
</script>

<style scoped>
.menu-item {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
@import "../scss/variable";
@import "../scss/bootstrap";

.arrow-yellow {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url("../assets/home/images/arrow-down-yellow.png") center center
    no-repeat;
  background-size: contain;
  margin-left: 10px;
}

.navbar-nav .nav-link {
  padding: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &::after {
    display: block;
    content: "";
    border-bottom: 1px solid #fff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &.active,
  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}

.flag {
  height: 15px;
}

@media (max-width: 389px) {
  .lang-changer.logged {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .nav-dtac-at-home-info {
    /*display: none;*/
    visibility: hidden;
    position: absolute;
    top: $main-nav-height;
    background-color: rgba($dtac-dark-blue, 0.9);
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;

    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.6s;

    &.show {
      /*display: block;*/
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
    }
    > .navbar-nav {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
