import axios from 'axios';

export const GeoSearchService = {
    batch,
    detail,
    autocomplete
};

const geoInstance = axios.create({
    baseURL: "https://pin-point.co/g/search"
});

function getCdgToken() {
    const hostURL = window.location.hostname;
    switch (hostURL) {
        case "athome.dtac.co.th":
            return "b99d4d5ed41fb6d9717998ffd9bb1951117e52c79863cb52b06d2e470e51bb47875a2e0bd1bba2e6";
        case "athome.test.dtac.co.th":
            return "51c8e1b32c7c4a8d9fc3ab8a45275b1f4679e24ccba2802f347111e42cb9f6ba6f33bf120fdd9013";
        case "localhost":
            return "3cb29afb921ed639d2bc4ce0053293a04397a868f5446bea74290352c27b87d8b8cc64decb32ce9a";
        default:
            return "";
    }
}

function autocomplete(keyword) {
    const params = {
        keyword,
        maxResult: 5,
        key: getCdgToken()
    };
    return geoInstance.post('/autocomplete', params)
        .then((response) => {
            switch (response.status) {
                case 200:
                    return response.data;

                default:
                    console.log(response.status + ": " + response.statusText);
                    break;
            }
        })
        .catch(function (error) {
            console.log('this: ', error);
        })
}
function detail(location_id, lang, ) {
    const params = {
        language: lang,
        locationid: location_id,
        key: getCdgToken()
    };

    return geoInstance.post('/detailsLanguage', params)
        .then((response) => {
            switch (response.status) {
                case 200:
                    return response.data;

                default:
                    console.log(response.status + ": " + response.statusText);
                    break;
            }
        })
        .catch(function (error) {
            // handle error
            console.log('this: ', error);
        })
}
function batch(address) {
    const params = {
        data: [
            {
                input: address
            }
        ],
        key: getCdgToken()
    };
    console.log('params..', params);
    return geoInstance.post('/batch', params)
        .then((response) => {
            switch (response.status) {
                case 200:
                    console.log(": ", response.data);
                    return response.data;

                default:
                    console.log(response.status + ": " + response.statusText);
                    break;
            }
        })
        .catch(function (error) {
            // handle error
            console.log('this: ', error);
        })
}
