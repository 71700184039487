import { authHeader } from '../router';
import axios from 'axios';

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.config.url);
  if (error.response.status === 401) {
    localStorage.removeItem('token');
    if(!error.response.config.url.endsWith("logout")){
      logout();
      location.reload();
    }
  }
  return Promise.reject(error)
})

export const numberService = {
  profile,
  balance,
  usage,
  additionalPack,
  packOnShelf,
  saveAdditionalPack,
  logout
};

function profile() {

  return axios.get('/customer/profile', authHeader())
    // .then(handleResponse)
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      // handle error
      console.log('this: ', error);
    })
}

function logout() {
  const header = authHeader();
  localStorage.removeItem('token');
  return axios.post('/customer/logout', {}, header)
    ///.then(handleResponse)
    .catch(function (error) {
      console.log(error);
    });
}

function balance() {
  return axios.get('/customer/api/balance', authHeader())
  // .then(handleResponse)
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      // handle error
      console.log('this: ', error);
    })
}

function usage() {
  return axios.get('/customer/api/internet', authHeader())
  // .then(handleResponse)
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      // handle error
      console.log('this: ', error);
    })
}

function additionalPack() {
  return axios.get('/customer/api/package', authHeader())
  // .then(handleResponse)
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      // handle error
      console.log('this: ', error);
    })
}

function packOnShelf() {
  return axios.get('/customer/api/packOnShelf', authHeader())
  // .then(handleResponse)
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      // handle error
      console.log('this: ', error);
    })
}

function saveAdditionalPack(telp_type, pack_data) {
  const header = authHeader();
  const reqData = {
    "action": "save",
    "telp_type": telp_type,
    "package": pack_data
  }
  return axios.post('/customer/api/saveAdditionalPack', JSON.stringify(reqData), header);
}


/*
function handleResponse(response) {
  console.log('handle');
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
        location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
 */
