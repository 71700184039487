import axios from 'axios';
import { authHeader } from '../router';

export const LocationService = {
    getProvinceDetail,
    getServiceAvailability,
    saveProspectCustomer,
    getEmployeeServiceAvailability,
    saveEmployeeProspectCustomer
};

function getProvinceDetail(request_id) {
    let requestUrl = "/customer/provinces"
    if ( request_id ) {
        requestUrl = requestUrl + "?id=" + request_id
    }
    return axios.get(requestUrl, authHeader())
        .then((response) => {
            return response.data.data;
        })
        .catch(function (error) {
            console.log('this: ', error);
        })
}

function getServiceAvailability(coordinates) {
    return axios.get("/maps/checkService", { params: coordinates }, authHeader())
        .then((response) => {
            return response.data.data;
        })
        .catch(function (error) {
            console.log('this: ', error);
        })
}

function saveProspectCustomer(prospectInfo) {
    return axios.post("/customer/prospect", prospectInfo, authHeader())
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            console.log('this: ', error);
        })
}

function getEmployeeServiceAvailability(coordinates) {
    return axios.get("/maps/checkServiceEmployee", { params: coordinates }, authHeader())
        .then((response) => {
            return response.data.data;
        })
        .catch(function (error) {
            console.log('this: ', error);
        })
}

function saveEmployeeProspectCustomer(prospectInfo) {
    return axios.post("/customer/employee", prospectInfo, authHeader())
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            console.log('this: ', error);
        })
}