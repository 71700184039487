import { numberService } from '../services';

export const number = {
  namespaced: true,
  state: {
    all: {}
  },
  actions: {
    profile({ commit }) {
      commit('getAllRequest');

      numberService.profile()
        .then(
          users => commit('getAllSuccess', users),
          error => commit('getAllFailure', error)
        );
    },
    balance({ commit }) {
      numberService.balance()
        .then(
          users => commit('getBalance', users),
          error => commit('getAllFailure', error)
        );
    }
  },
  mutations: {
    getAllRequest(state) {
      state.all = { loading: true };
    },
    getAllSuccess(state, users) {
      state.all = { items: users };
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
    getBalance(data){
      return data
    }
  }
}
